import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationErrorConfigData } from '../models/application-error-configs.model';
import { AppErrorConfigService } from '../services/app-error-config.service';

@Pipe({
  name: 'applicationError',
})
export class ApplicationErrorPipe implements PipeTransform {
  constructor(private appErrorConfigService: AppErrorConfigService) {}

  transform(templateMessage: string): ApplicationErrorConfigData {
    return this.appErrorConfigService.getConfig(templateMessage);
  }
}
