import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  NbInputModule,
  NbFormFieldModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbSpinnerModule,
  NbLayoutModule,
} from '@nebular/theme';
import { FormFieldConfigService } from './services/form-field-config/form-field-config.service';
import { Options } from './models/options.model';
import { FORM_FIELD_CONFIGS } from './services/tokens';
import { ValidatedInputDirective } from './directives/validated-input/validated-input.directive';

@NgModule({
  declarations: [ValidatedInputDirective],
  imports: [
    CommonModule,
    NbInputModule,
    NbFormFieldModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbIconModule,
    NbSpinnerModule,
    NbLayoutModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [ValidatedInputDirective],
  providers: [FormFieldConfigService],
})
export class UiFormInputsModule {
  static forRoot(options: Options) {
    return {
      ngModule: UiFormInputsModule,
      providers: [
        {
          provide: FORM_FIELD_CONFIGS,
          useValue: options.formFieldConfigs,
        },
        FormFieldConfigService,
      ],
    };
  }

  static forChild(options: Options) {
    return {
      ngModule: UiFormInputsModule,
      providers: [
        {
          provide: FORM_FIELD_CONFIGS,
          useValue: options.formFieldConfigs,
        },
        FormFieldConfigService,
      ],
    };
  }
}
