import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '@groceriya/utils';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { AUTH_URL } from '../../services/tokens';

@Component({
  selector: 'groceriya-auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginFormGroup: FormGroup;
  public isProcessing = false;
  public showPassword = false;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(AUTH_URL) public authUrl: string,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  get isFormValid() {
    return this.loginFormGroup.valid;
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getFormControl(key: string) {
    return this.loginFormGroup.get(key) as FormControl;
  }

  onSubmit() {
    if (!this.loginFormGroup.valid) {
      return;
    }

    this.isProcessing = true;

    this.subscriptions.push(
      this.authService
        .login(
          this.getFormControl('email').value,
          this.getFormControl('password').value
        )
        .subscribe(
          () => {
            this.isProcessing = false;
            this.router.navigate(['/']);
          },
          (error) => {
            this.isProcessing = false;
            this.notificationService.pushError(error.message);
          }
        )
    );
  }

  formFieldStatus(isValid: boolean) {
    return isValid ? 'success' : 'danger';
  }

  initializeForm(): void {
    this.loginFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }
}
