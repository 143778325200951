import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@groceriya/utils';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { AUTH_URL } from '../../services/tokens';

@Component({
  selector: 'groceriya-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public resetPasswordFormGroup: FormGroup;
  public isProcessing = false;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(AUTH_URL) public authUrl: string,
    public formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  get isFormValid() {
    return this.resetPasswordFormGroup.valid;
  }

  get email() {
    return this.route.snapshot.queryParams?.email;
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getFormControl(key: string) {
    return this.resetPasswordFormGroup.get(key) as FormControl;
  }

  onSubmit() {
    if (!this.resetPasswordFormGroup.valid) {
      return;
    }

    this.isProcessing = true;
    this.subscriptions.push(
      this.authService
        .resetPassword(
          this.getFormControl('email').value,
          this.getFormControl('passwordResetCode').value,
          this.getFormControl('password').value
        )
        .subscribe(
          () => {
            this.isProcessing = false;
            this.notificationService.push(
              'success',
              'Password successfully updated',
              'Your password successfully updated. Please login.'
            );
            this.router.navigate([`/${this.authUrl}/login`]);
          },
          (error) => {
            this.isProcessing = false;
            this.notificationService.pushError(error.message);
          }
        )
    );
  }

  private initializeForm(): void {
    this.resetPasswordFormGroup = this.formBuilder.group({
      email: [this.email, [Validators.required, Validators.email]],
      passwordResetCode: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }
}
