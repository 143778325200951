export enum UserRole {
  SystemAdmin = 1,
  MarketingManager = 20,
  ShippingAndHandlingManager = 30,
  GeneralManager = 40,
  SalesOfficer = 42,
  MarketingAgent = 22,
  Picker = 32,
  Packer = 33,
  Driver = 34,
}
