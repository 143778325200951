import { FormFieldConfigs } from '@groceriya/ui/form-inputs';

const configs: FormFieldConfigs = {
  'request-password-reset-email-text-input': {
    metadata: {
      placeholder: 'Email Address',
      caption: 'Please enter the email address',
    },
    validationErrors: [
      {
        rule: 'required',
        errorMessage: 'Email address is required',
      },
      {
        rule: 'email',
        errorMessage: 'Email address is invalid',
      },
    ],
  },
  'user-login-email-text-input': {
    metadata: {
      placeholder: 'Email Address',
      caption: 'Please enter the email address',
    },
    validationErrors: [
      {
        rule: 'required',
        errorMessage: 'Email address is required',
      },
      {
        rule: 'email',
        errorMessage: 'Email address is invalid',
      },
    ],
  },
  'user-login-password-text-input': {
    metadata: {
      placeholder: 'Password',
      caption: 'Please enter the password',
    },
    validationErrors: [
      {
        rule: 'required',
        errorMessage: 'Password is required',
      },
    ],
  },
  'password-reset-reset-code-text-input': {
    metadata: {
      placeholder: 'Reset Code',
      caption: 'Please enter the password reset code',
    },
    validationErrors: [
      {
        rule: 'required',
        errorMessage: 'Password reset code is required',
      },
    ],
  },
  'password-reset-email-text-input': {
    metadata: {
      placeholder: 'Email Address',
      caption: 'Please enter the email address',
    },
    validationErrors: [
      {
        rule: 'required',
        errorMessage: 'Email address is required',
      },
      {
        rule: 'email',
        errorMessage: 'Email address is invalid',
      },
    ],
  },
  'password-reset-password-text-input': {
    metadata: {
      placeholder: 'New Password',
      caption: 'Please enter the new password',
    },
    validationErrors: [
      {
        rule: 'required',
        errorMessage: 'New password is required',
      },
    ],
  },
};

export default configs;
