import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
} from '@nebular/theme';
import { UtilsModule, LogLevel } from '@groceriya/utils';
import { ApiModule } from '@groceriya/api';
import { environment } from '../../environments/environment';
import { AuthFeatureModule } from '@groceriya/auth/feature';
import ApplicationErrorConfigs from '../../configs/application-error-configs';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    UtilsModule.forRoot({
      baseUrl: environment.baseUrl,
      logLevel: environment.production ? LogLevel.ERRORS : LogLevel.DEBUG,
      applicationMetadata: {
        appID: environment.appID,
        appType: environment.appType,
        appVersion: environment.appVersion,
      },
      applicationErrorConfigs: ApplicationErrorConfigs,
    }),
    HttpClientModule,
    AuthFeatureModule.forRoot({ authUrl: '/auth' }),
    ApiModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forChild(),
  ],
})
export class CoreModule {}
