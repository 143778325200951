/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApplicationMetaDataService, AuthTokenService } from '@groceriya/utils';
import { responseMapper } from '../core';
import { LoginRequest, ResetPasswordRequest } from '../models/request-models';
import {
  ChangePasswordResponse,
  ExtendJwtResponse,
  IssueJwtResponse,
  LoginResponse,
} from '../models/response-models';
import { PayloadMapToken } from '../models/payload-map-token.model';
import { MessageToken } from '../models/message-token.model';

@Injectable()
export class AuthEndpoints {
  constructor(
    private http: HttpClient,
    private authTokenService: AuthTokenService,
    private applicationMetaDataService: ApplicationMetaDataService
  ) {}

  public login(email: string, password: string): Observable<LoginResponse> {
    const requestData: LoginRequest = {
      email,
      passcode: password,
      appType: this.applicationMetaDataService.data.appType,
      appVersion: this.applicationMetaDataService.data.appVersion,
      appID: this.applicationMetaDataService.data.appID,
    };
    return this.http
      .post<PayloadMapToken<LoginResponse>>(`/api/auth/login`, requestData)
      .pipe(responseMapper());
  }

  public issueJwt(): Observable<IssueJwtResponse> {
    return this.authTokenService.getRefreshToken().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders({ refreshToken: token });
        return this.http.post<PayloadMapToken<IssueJwtResponse>>(
          `/api/auth/issue/jwt`,
          null,
          { headers }
        );
      }),
      responseMapper()
    );
  }

  public extendJwt(): Observable<ExtendJwtResponse> {
    return this.http
      .post<PayloadMapToken<ExtendJwtResponse>>(`/api/auth/extend/jwt`, null)
      .pipe(responseMapper());
  }

  public requestOtp(email: string): Observable<void> {
    const httpParams = new HttpParams().set('email', email);
    return this.http
      .post<MessageToken>('/api/auth/password/otp', null, {
        params: httpParams,
      })
      .pipe(responseMapper());
  }

  public requestPasswordResetCode(email: string): Observable<void> {
    const httpParams = new HttpParams().set('email', email);
    return this.http
      .post<MessageToken>('/api/auth/password/code', null, {
        params: httpParams,
      })
      .pipe(responseMapper());
  }

  public resetPassword(
    email: string,
    resetCode: string,
    newPassword: string
  ): Observable<void> {
    const requestData: ResetPasswordRequest = {
      email,
      resetCode,
      newpwd: newPassword,
    };
    return this.http
      .post<MessageToken>(`/api/auth/password/reset`, requestData)
      .pipe(responseMapper());
  }

  public changePassword(
    newPassword: string,
    oldPassword: string
  ): Observable<ChangePasswordResponse> {
    const httpParams = new HttpParams()
      .set('oldPwd', oldPassword)
      .set('newPwd', newPassword);
    return this.http
      .post<PayloadMapToken<ChangePasswordResponse>>(
        '/api/auth/password/change',
        null,
        { params: httpParams }
      )
      .pipe(responseMapper());
  }
}
