import { Inject, Injectable, NgZone } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { AUTH_URL } from '../services/tokens';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private ngZone: NgZone,
    private router: Router,
    @Inject(AUTH_URL)
    private authUrl: string
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.auth.getUser().pipe(
      map((user) => {
        if (!user) {
          throw new Error(
            'User is not logged in. Always include the role guard followed by an auth guard.'
          );
        }
        const allowedRoles: number[] = next.data.allowedRoles || [];
        return allowedRoles.filter((r) => r === user.role).length > 0;
      }),
      tap((allowed) => {
        if (!allowed) {
          console.log('NOT allowed');
          this.ngZone.run(() => {
            this.router.navigate([this.authUrl]);
          });
        }
      })
    );
  }
}
