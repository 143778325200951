import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@groceriya/utils';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { AUTH_URL } from '../../services/tokens';

@Component({
  selector: 'groceriya-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss'],
})
export class RequestPasswordResetComponent implements OnInit, OnDestroy {
  public passwordResetRequestFormGroup: FormGroup;
  public isProcessing = false;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(AUTH_URL) public authUrl: string,
    public formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  get isFormValid() {
    return this.passwordResetRequestFormGroup.valid;
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getFormControl(key: string) {
    return this.passwordResetRequestFormGroup.get(key) as FormControl;
  }

  onSubmit() {
    if (!this.passwordResetRequestFormGroup.valid) {
      return;
    }

    this.isProcessing = true;
    this.subscriptions.push(
      this.authService
        .requestPasswordReset(this.getFormControl('email').value)
        .subscribe(
          () => {
            this.isProcessing = false;
            this.notificationService.push(
              'success',
              'E-mail sent',
              'An e-mail sent to your email address with Reset Code. Please check your emails and continue with that Code'
            );
            this.router.navigate([`/${this.authUrl}/reset-password`], {
              queryParams: {
                email: this.getFormControl('email').value,
              },
            });
          },
          (error) => {
            this.isProcessing = false;
            this.notificationService.pushError(error.message);
          }
        )
    );
  }

  private initializeForm(): void {
    this.passwordResetRequestFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
}
