import { Inject, Injectable } from '@angular/core';
import { ApplicationErrorConfigs } from '../models/application-error-configs.model';
import { APP_ERROR_CONFIGS } from './tokens';

@Injectable()
export class AppErrorConfigService {
  constructor(
    @Inject(APP_ERROR_CONFIGS)
    private appErrorConfigs: ApplicationErrorConfigs
  ) {}

  getConfig(templateMessage: string) {
    return this.appErrorConfigs[templateMessage];
  }
}
