import { NgModule, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthTokenService } from '@groceriya/utils';
import { AuthEndpoints } from './endpoints/auth.endpoints';
import {
  BrandEndpoints,
  CategoryEndpoints,
  CustomerEndpoints,
  OrderEndpoints,
  ProductEndpoints,
  PromotionEndpoints,
  SupplierEndpoints,
  UserEndpoints,
  VehicleEndpoints,
} from './endpoints';

const providers = [
  AuthEndpoints,
  BrandEndpoints,
  CategoryEndpoints,
  CustomerEndpoints,
  OrderEndpoints,
  ProductEndpoints,
  PromotionEndpoints,
  SupplierEndpoints,
  UserEndpoints,
  VehicleEndpoints,
];

@NgModule({})
export class ApiModule {
  public static forRoot() {
    return {
      ngModule: ApiModule,
      providers,
    };
  }

  constructor(
    @Optional() http: HttpClient,
    @Optional() authToken: AuthTokenService
  ) {
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule!'
      );
    }

    if (!authToken) {
      throw new Error(
        'You need to import UtilsModule.forRoot() in your AppModule first!'
      );
    }
  }
}
