import { map } from 'rxjs/operators';
import { MessageToken } from '../../models/message-token.model';

export function responseMapper<S>() {
  return map((response: MessageToken & { payload?: S }) => {
    if (response.code >= 200 && response.code < 300) {
      if (response.payload === undefined) {
        return; // void
      }
      return response.payload;
    }
    throw new Error(response.message);
  });
}
