import { NgModule, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Options } from './models/options.model';
import { LoggerService } from './services/logger.service';
import {
  APP_ERROR_CONFIGS,
  APP_METADATA,
  BASE_URL,
  LOG_LEVEL,
} from './services/tokens';
import { AuthTokenService } from './services/auth-token.service';
import { BaseUrlInterceptor } from './interceptors/base-url.interceptor';
import { AuthTokenInterceptor } from './interceptors/auth-token.interceptor';
import { ApplicationMetaDataService } from './services/application-meta-data.service';
import { NotificationService } from './services/notification.service';
import { NbToastrModule } from '@nebular/theme';
import { ApplicationErrorPipe } from './pipes/application-error.pipe';
import { AppErrorConfigService } from './services/app-error-config.service';

@NgModule({
  imports: [CommonModule, NbToastrModule.forRoot()],
  providers: [],
  declarations: [ApplicationErrorPipe],
  exports: [ApplicationErrorPipe],
})
export class UtilsModule {
  static forRoot(options: Options) {
    return {
      ngModule: UtilsModule,
      providers: [
        // configuration values
        { provide: BASE_URL, useValue: options.baseUrl },
        { provide: LOG_LEVEL, useValue: options.logLevel },
        { provide: APP_METADATA, useValue: options.applicationMetadata },
        {
          provide: APP_ERROR_CONFIGS,
          useValue: options.applicationErrorConfigs,
        },

        // interceptors
        {
          provide: HTTP_INTERCEPTORS,
          useClass: BaseUrlInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthTokenInterceptor,
          multi: true,
        },

        AuthTokenService,
        LoggerService,
        ApplicationMetaDataService,
        NotificationService,
        AppErrorConfigService,
      ],
    };
  }

  constructor(@Optional() http: HttpClient) {
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule first in your AppModule!'
      );
    }
  }
}
