import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemUser, SystemUserAvatarWrapper } from '@groceriya/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { responseMapper } from '../core';
import { PayloadListToken } from '../models/payload-list-token.model';
import { PayloadMapToken } from '../models/payload-map-token.model';
import { SystemUserCreationResponse } from '../models/response-models';

@Injectable()
export class UserEndpoints {
  constructor(private http: HttpClient) {}

  createUser(
    userDetails: Partial<SystemUser>
  ): Observable<SystemUserCreationResponse> {
    return this.http
      .post<PayloadMapToken<SystemUserCreationResponse>>(
        '/api/users/',
        userDetails
      )
      .pipe(responseMapper());
  }

  getUsers(): Observable<Array<SystemUserAvatarWrapper>> {
    return this.http
      .get<PayloadListToken<SystemUserAvatarWrapper>>('/api/users/')
      .pipe(responseMapper());
  }

  getUser(userId: number): Observable<SystemUser> {
    return this.http
      .get<PayloadListToken<SystemUser>>(`/api/users/${userId}`)
      .pipe(
        responseMapper(),
        map((response) => {
          if (response && response.length === 0) {
            return null;
          }
          return response.pop();
        })
      );
  }
}
