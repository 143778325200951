import { Inject, Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { asyncScheduler, forkJoin, Observable, scheduled, timer } from 'rxjs';
import { AuthEndpoints } from '@groceriya/api';
import { AuthTokenService, LoggerService } from '@groceriya/utils';
import { UserRole } from '@groceriya/models';
import { AuthUser } from '../models/auth-user.model';
import { AuthToken } from '../models/auth-token.model';
import jwtDecode from 'jwt-decode';
import { AUTH_URL } from './tokens';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  constructor(
    private authToken: AuthTokenService,
    private authEndpoints: AuthEndpoints,
    private loggerService: LoggerService,
    private router: Router,
    @Inject(AUTH_URL)
    private authUrl: string
  ) {
    this.updateAuthTokenJob();
  }

  isAuthenticated() {
    // TODO: Implement
    return this.authToken.getAuthToken().pipe(
      map((token) => {
        return !!token;
      })
    );
  }

  login(email: string, password: string) {
    const encodedPassword = btoa(password);
    return this.authEndpoints.login(email, encodedPassword).pipe(
      map((response) => {
        this.authToken.setAuthToken(response.jwt);
        this.authToken.setRefreshToken(response.rft);
        return;
      })
    );
  }

  requestPasswordReset(email: string) {
    return this.authEndpoints.requestPasswordResetCode(email);
  }

  resetPassword(email: string, resetCode: string, newPassword: string) {
    const encodedPassword = btoa(newPassword);
    return this.authEndpoints.resetPassword(email, resetCode, encodedPassword);
  }

  getUser() {
    return this.authToken.getAuthToken().pipe(
      map((token) => {
        const tokenData: AuthToken = jwtDecode(token);
        if (!tokenData) {
          return null;
        }
        const userData: AuthUser = {
          userid: tokenData.userid,
          email: tokenData.email,
          username: tokenData.sub,
          role: Number.parseInt(tokenData.roles) as UserRole,
        };
        return userData;
      })
    );
  }

  logout() {
    // TODO: Implement
    this.authToken.removeRefreshToken().subscribe();
    this.authToken.removeAuthToken().subscribe();
    this.router.navigate([this.authUrl]);
  }

  updateAuthToken() {
    return this.authEndpoints.issueJwt().pipe(
      switchMap((res) => {
        if (!res) {
          return scheduled<void>([], asyncScheduler);
        }
        return this.authToken.setAuthToken(res.jwt);
      })
    );
  }

  private canUpdateToken(): Observable<{
    canUpdate: boolean;
    hasError: boolean;
  }> {
    return forkJoin({
      authToken: this.authToken.getAuthToken(),
      isRefreshTokenExist: this.authToken.refreshTokenExist(),
    }).pipe(
      map(({ authToken, isRefreshTokenExist }) => {
        if (!authToken && isRefreshTokenExist) {
          return { canUpdate: true, hasError: false }; // can update
        }

        if (!authToken && !isRefreshTokenExist) {
          throw new Error('Refresh token not found');
        }

        const tokenData: AuthToken = jwtDecode(authToken);
        if (!tokenData) {
          return { canUpdate: true, hasError: false }; // can update
        }

        const currentTime = new Date().getTime(); // in mili sec
        const issTime = tokenData.iat * 1000; // in mili sec
        const expTime = tokenData.exp * 1000; // in mili sec
        const tokenLifeTime = expTime - issTime; // in mili sec
        const timeToExp = expTime - currentTime; // in mili sec

        const canUpdate = tokenLifeTime / 4 > timeToExp;

        if (!canUpdate) {
          return { canUpdate: false, hasError: false };
        }

        if (isRefreshTokenExist) {
          return { canUpdate: true, hasError: false };
        }

        throw new Error('Refresh token not found');
      }),
      catchError((error) => {
        this.loggerService.error(error.message);
        return scheduled(
          [{ canUpdate: false, hasError: true }],
          asyncScheduler
        );
      })
    );
  }

  private updateAuthTokenJob() {
    timer(0, 1000 * 60)
      .pipe(
        switchMap(() => {
          return this.canUpdateToken();
        }),
        switchMap(({ canUpdate, hasError }) => {
          if (canUpdate) {
            this.loggerService.info('Updating JWT');
            return this.updateAuthToken();
          }
          if (hasError) {
            this.loggerService.info(
              'Cannot Update JWT and user is logging out'
            );
            this.logout();
          }
          return scheduled<void>([], asyncScheduler);
        })
      )
      .subscribe();
  }
}
