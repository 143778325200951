<nb-card class="card-container">
  <nb-card-body class="card-body-container">
    <div class="card-body-container-inner">
      <h1 class="title">Reset Password</h1>
      <p class="sub-title">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Earum, sunt.
      </p>

      <div class="form-container">
        <form [formGroup]="resetPasswordFormGroup" (submit)="onSubmit()">
          <nb-form-field>
            <input
              nbInput
              groceriyaValidatedInput
              fullWidth
              formFieldConfigKey="password-reset-email-text-input"
              type="email"
              [formControl]="getFormControl('email')"
              autocomplete="email"
            />
          </nb-form-field>

          <nb-form-field>
            <input
              name="reset-password-code"
              nbInput
              groceriyaValidatedInput
              fullWidth
              formFieldConfigKey="password-reset-reset-code-text-input"
              type="text"
              [formControl]="getFormControl('passwordResetCode')"
              autocomplete="one-time-code"
            />
          </nb-form-field>

          <nb-form-field>
            <input
              nbInput
              groceriyaValidatedInput
              fullWidth
              formFieldConfigKey="password-reset-password-text-input"
              type="password"
              [formControl]="getFormControl('password')"
            />
          </nb-form-field>

          <button
            class="action-button"
            nbButton
            fullwidth
            shape="round"
            size="medium"
            status="primary"
            [nbSpinner]="isProcessing"
            nbSpinnerStatus="primary"
            [disabled]="!isFormValid || isProcessing"
            autocomplete="new-password"
          >
            Reset Password
          </button>

          <span class="label-link">
            <a [routerLink]="authUrl + '/login'" class="back-to-login caption-2"
              >Back to Log In</a
            >
          </span>
        </form>
      </div>
    </div>
  </nb-card-body>
  <p class="copyright-text">Copyright © 2021 Groceria</p>
</nb-card>
