import { Injectable } from '@angular/core';
import { asyncScheduler, scheduled } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthTokenService {
  private readonly AUTH_TOKEN = 'auth_token';
  private readonly REFRESH_TOKEN = 'refresh_token';

  getAuthToken() {
    const token = localStorage.getItem(this.AUTH_TOKEN);
    return scheduled([token], asyncScheduler);
  }

  setAuthToken(token: string) {
    localStorage.setItem(this.AUTH_TOKEN, token);
    return scheduled([token], asyncScheduler);
  }

  removeAuthToken() {
    localStorage.removeItem(this.AUTH_TOKEN);
    return scheduled<void>([], asyncScheduler);
  }

  authTokenExist() {
    return this.getAuthToken().pipe(
      map((token) => {
        return !!token;
      })
    );
  }

  getRefreshToken() {
    const token = localStorage.getItem(this.REFRESH_TOKEN);
    return scheduled([token], asyncScheduler);
  }

  setRefreshToken(token: string) {
    localStorage.setItem(this.REFRESH_TOKEN, token);
    return scheduled([token], asyncScheduler);
  }

  removeRefreshToken() {
    localStorage.removeItem(this.REFRESH_TOKEN);
    return scheduled([], asyncScheduler);
  }

  refreshTokenExist() {
    return this.getAuthToken().pipe(
      map((token) => {
        return !!token;
      })
    );
  }
}
