import {
  Directive,
  ElementRef,
  Host,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoggerService } from '@groceriya/utils';
import { NbSelectComponent } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { FormFieldConfigService } from '../../services/form-field-config/form-field-config.service';

@Directive({
  selector: '[groceriyaValidatedInput]',
})
export class ValidatedInputDirective implements OnInit, OnChanges, OnDestroy {
  @Input()
  formControl: FormControl;

  @Input()
  formFieldConfigKey: string;

  @Input()
  helperTexts = true;

  // @HostBinding('class.status-success')
  // get success(): boolean {
  //   return this.getFormControlStatus() === 'success';
  // }

  @HostBinding('class.status-danger')
  get danger(): boolean {
    return this.getFormControlStatus() === 'danger';
  }

  private subscriptions: Subscription[] = [];

  private captionTextElement: any;
  private errorElements: { [error: string]: any } = {};

  private parentNode: any;

  private get formFieldConfig() {
    const config = this.formFieldConfigService.getConfig(
      this.formFieldConfigKey
    );
    if (!config) {
      throw new Error(
        `Form Field Config for ${this.formFieldConfigKey} is not available`
      );
    }
    return config;
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private formFieldConfigService: FormFieldConfigService,
    private logger: LoggerService,
    @Host() @Optional() private selectComponent: NbSelectComponent
  ) {}

  ngOnInit() {
    this.parentNode = this.renderer.parentNode(this.elementRef.nativeElement);
    this.setupSubscriptions();
    this.setPlaceholder();
  }

  ngOnChanges({ formControl }: SimpleChanges) {
    if (!formControl.firstChange) {
      this.clearSubscriptions();
      this.setupSubscriptions();
      this.setPlaceholder();
    }
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  private setPlaceholder() {
    if (!this.formFieldConfig.metadata.placeholder) {
      this.logger.warn('Placeholder not set for validated input');
      return;
    }
    const placeholderText = this.formFieldConfig.metadata.placeholder;
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'placeholder',
      placeholderText
    );
    if (this.selectComponent) {
      this.selectComponent.placeholder = placeholderText;
    }
  }

  private setupSubscriptions() {
    this.updateCaptionText();
    this.updateErrors();

    this.subscriptions.push(
      this.formControl.valueChanges.subscribe(() => {
        // scheduling the change to happen in next tick to give time for form status changes to propagate
        setTimeout(() => {
          this.updateCaptionText();
          this.updateErrors();
        }, 0);
      })
    );
  }

  private clearSubscriptions() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private updateCaptionText() {
    const removeCaptionText = () => {
      if (this.captionTextElement) {
        this.renderer.removeChild(this.parentNode, this.captionTextElement);
        this.captionTextElement = null;
      }
    };

    const addCaptionText = () => {
      if (!this.captionTextElement && this.helperTexts) {
        if (!this.formFieldConfig.metadata.caption) {
          this.logger.warn(
            'Caption text not available in formField config for element'
          );
          return;
        }
        this.captionTextElement = this.createCaptionTextElement(
          this.formFieldConfig.metadata.caption
        );
        this.renderer.appendChild(this.parentNode, this.captionTextElement);
      }
    };

    if (this.formControl.dirty && this.formControl.invalid) {
      removeCaptionText();
      return;
    }

    addCaptionText();

    // set the status color
    // if (
    //   this.formControl.dirty &&
    //   this.formControl.valid &&
    //   this.captionTextElement
    // ) {
    //   this.renderer.addClass(this.captionTextElement, 'status-success');
    // } else {
    //   this.renderer.removeClass(this.captionTextElement, 'status-success');
    // }
  }

  private updateErrors() {
    const removeErrorText = (error: string) => {
      if (this.errorElements[error]) {
        this.renderer.removeChild(this.parentNode, this.errorElements[error]);
        delete this.errorElements[error];
      }
    };

    const addErrorText = (error: string) => {
      if (!this.errorElements[error] && this.helperTexts) {
        const validationError = this.formFieldConfig.validationErrors.find(
          (e) => e.rule === error
        );
        if (!validationError) {
          this.logger.warn(
            `Error message not available for ${error} in formFieldConfig for element`
          );
          return;
        }
        this.errorElements[error] = this.createErrorElement(
          validationError.errorMessage
        );
        this.renderer.appendChild(this.parentNode, this.errorElements[error]);
      }
    };

    if (this.formControl.pristine || this.formControl.valid) {
      for (const key of Object.keys(this.errorElements)) {
        removeErrorText(key);
      }
      return;
    }

    if (this.formControl.dirty) {
      for (const error of Object.keys(this.formControl.errors || {})) {
        addErrorText(error);
      }
    }
  }

  private createErrorElement(message: string) {
    const p = this.renderer.createElement('p');
    const value = this.renderer.createText(message);
    this.renderer.addClass(p, 'caption');
    this.renderer.addClass(p, 'form-caption');
    this.renderer.addClass(p, 'status-danger');
    this.renderer.setStyle(p, 'padding-top', '0.3rem');
    this.renderer.setStyle(p, 'margin-bottom', '0.3rem');
    this.renderer.appendChild(p, value);

    return p;
  }

  private createCaptionTextElement(caption: string, success?: boolean) {
    const p = this.renderer.createElement('p');
    const value = this.renderer.createText(caption);
    this.renderer.addClass(p, 'caption');
    this.renderer.addClass(p, 'form-caption');
    this.renderer.setStyle(p, 'padding-top', '0.3rem');
    this.renderer.setStyle(p, 'margin-bottom', '0.3rem');
    if (success) {
      this.renderer.addClass(p, 'status-success');
    }
    this.renderer.appendChild(p, value);

    return p;
  }

  private getFormControlStatus() {
    if (!this.formControl) {
      throw new Error('Form control is not defined!');
    }

    if (this.formControl.dirty) {
      if (this.formControl.valid) {
        // return 'success';
        return 'basic';
      } else {
        return 'danger';
      }
    }
    return 'basic';
  }
}
