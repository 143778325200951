<nb-card class="card-container">
  <nb-card-body class="card-body-container">
    <div class="card-body-container-inner">
      <h1 class="title">Login</h1>
      <p class="sub-title">Hello! Log in with your email.</p>

      <div class="form-container">
        <form [formGroup]="loginFormGroup" (submit)="onSubmit()">
          <nb-form-field>
            <input
              nbInput
              groceriyaValidatedInput
              fullWidth
              formFieldConfigKey="user-login-email-text-input"
              type="text"
              [formControl]="getFormControl('email')"
            />
          </nb-form-field>

          <span class="label-link">
            <a
              [routerLink]="authUrl + '/request-password-reset'"
              class="forgot-password caption-2"
              >Forgot Password?</a
            >
          </span>

          <nb-form-field>
            <input
              nbInput
              groceriyaValidatedInput
              fullWidth
              formFieldConfigKey="user-login-password-text-input"
              type="password"
              [formControl]="getFormControl('password')"
            />
          </nb-form-field>

          <button
            class="action-button"
            nbButton
            shape="round"
            size="medium"
            status="primary"
            [nbSpinner]="isProcessing"
            nbSpinnerStatus="primary"
            [disabled]="!isFormValid || isProcessing"
          >
            Log In
          </button>
        </form>
      </div>
    </div>
  </nb-card-body>
  <p class="copyright-text">Copyright © 2021 Groceria</p>
</nb-card>
