import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthTokenService } from '../services/auth-token.service';
import { LoggerService } from '../services/logger.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(
    private authToken: AuthTokenService,
    private logger: LoggerService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.authToken.getAuthToken().pipe(
      switchMap((token) => {
        let headers = request.headers;
        if (token) {
          headers = request.headers.set('Authorization', `Bearer ${token}`);
          this.logger.debug('Auth header added to request', token);
        }

        const authenticatedRequest = request.clone({ headers });
        return next.handle(authenticatedRequest);
      })
    );
  }
}
