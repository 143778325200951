/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetCustomersStatusesResponse } from '../models/response-models';
import { responseMapper } from '../core';
import { Customer } from '@groceriya/models';
import { PayloadMapToken } from '../models/payload-map-token.model';
import { map } from 'rxjs/operators';
import { PayloadListToken } from '../models/payload-list-token.model';

@Injectable()
export class CustomerEndpoints {
  constructor(private http: HttpClient) {}

  getCustomers(
    offset?: string,
    count?: string,
    query?: string
  ): Observable<Array<Customer>> {
    if (!query) {
      query = '';
    }
    const httpParams = new HttpParams({
      fromObject: { offset, count, query },
    });
    return this.http
      .get<PayloadListToken<Customer>>('/api/customer/', { params: httpParams })
      .pipe(responseMapper());
  }

  addCustomer(
    requestData: Omit<
      Customer,
      'customerId' | 'registrationDate' | 'loyaltyEarned'
    >
  ) {
    return this.http
      .post<PayloadListToken<Customer>>(`/api/customer/`, requestData)
      .pipe(
        responseMapper(),
        map((response) => {
          if (response && response.length === 0) {
            return null;
          }
          return response.pop();
        })
      );
  }

  getCustomer(customerId: number): Observable<Customer> {
    return this.http
      .get<PayloadListToken<Customer>>(`/api/customer/${customerId}`)
      .pipe(
        responseMapper(),
        map((response) => {
          if (response && response.length === 0) {
            return null;
          }
          return response.pop();
        })
      );
  }

  getCustomersStatuses(): Observable<GetCustomersStatusesResponse> {
    return this.http
      .get<PayloadMapToken<GetCustomersStatusesResponse>>(
        `/api/customer/act/compact`
      )
      .pipe(responseMapper());
  }
}
