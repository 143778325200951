import { NgModule, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  NbButtonModule,
  NbCardModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbSpinnerModule,
} from '@nebular/theme';
import { LoggerService, UtilsModule } from '@groceriya/utils';
import { AuthEndpoints } from '@groceriya/api';
import { RouterModule } from '@angular/router';
import { UiFormInputsModule } from '@groceriya/ui/form-inputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { AuthService } from './services/auth.service';
import { AUTH_URL } from './services/tokens';
import { AuthGuard } from './guards/auth.guard';
import { Options } from './models/options.model';
import { LoginComponent } from './components/login/login.component';
import { ActivateComponent } from './components/activate/activate.component';
import { LogoutComponent } from './components/logout/logout.component';
import { LayoutComponent } from './components/layout/layout.component';
import { RequestPasswordResetComponent } from './components/request-password-reset/request-password-reset.component';
import FormFieldConfigs from './configs/form-field-configs';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RoleGuard } from './guards/role.guard';

@NgModule({
  imports: [
    CommonModule,
    UtilsModule,
    HttpClientModule,
    NbLayoutModule,
    NbCardModule,
    UiFormInputsModule.forChild({ formFieldConfigs: FormFieldConfigs }),
    NbButtonModule,
    NbSpinnerModule,
    NbEvaIconsModule,
    NbIconModule,
    NbInputModule,
    NbFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
  ],
  providers: [],
  declarations: [
    LoginComponent,
    ActivateComponent,
    LogoutComponent,
    LayoutComponent,
    RequestPasswordResetComponent,
    ResetPasswordComponent,
  ],
  exports: [
    LoginComponent,
    LogoutComponent,
    ActivateComponent,
    LayoutComponent,
    ResetPasswordComponent,
  ],
})
export class AuthFeatureModule {
  static forRoot(options: Options) {
    return {
      ngModule: AuthFeatureModule,
      providers: [
        { provide: AUTH_URL, useValue: options.authUrl },
        AuthService,
        AuthGuard,
        RoleGuard,
      ],
    };
  }

  constructor(
    @Optional() authEndpoints: AuthEndpoints,
    @Optional() logger: LoggerService
  ) {
    if (!authEndpoints) {
      throw new Error(
        'You need to import ApiModule.forRoot() in your AppModule first!'
      );
    }

    if (!logger) {
      throw new Error(
        'You need to import UtilsModule.forRoot() in your AppModule first!'
      );
    }
  }
}
