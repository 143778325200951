import { Inject, Injectable } from '@angular/core';
import { FormFieldConfigs } from '../../models/form-field-configs.model';
import { FORM_FIELD_CONFIGS } from '../tokens';

@Injectable()
export class FormFieldConfigService {
  constructor(
    @Inject(FORM_FIELD_CONFIGS) private formFieldConfigs: FormFieldConfigs
  ) {}

  getConfig(configKey: string) {
    return this.formFieldConfigs[configKey];
  }
}
