import { Injectable } from '@angular/core';
import {
  NbToastrService,
  NbGlobalPhysicalPosition,
  NbToastrConfig,
} from '@nebular/theme';
import { AppErrorConfigService } from './app-error-config.service';

@Injectable()
export class NotificationService {
  private nbToastrConfig: Partial<NbToastrConfig> = {
    hasIcon: false,
    position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
    duration: 10000,
    destroyByClick: true,
  };

  constructor(
    private appErrorConfigService: AppErrorConfigService,
    private toastr: NbToastrService
  ) {}

  push(
    status: 'primary' | 'success' | 'warning' | 'danger' | 'info',
    title: string,
    message: string
  ) {
    return this.toastr.show(message, title, { status, ...this.nbToastrConfig });
  }

  pushError(templateMesage: string) {
    const errorConfig = this.appErrorConfigService.getConfig(templateMesage);

    if (!errorConfig) {
      throw new Error('Application error configurations are not properly set');
    }

    return this.toastr.show(errorConfig.message, errorConfig.title, {
      status: 'danger',
      ...this.nbToastrConfig,
    });
  }
}
