import { Inject, Injectable } from '@angular/core';
import { ApplicationMetaData } from '../models/application-meta-data.model';
import { APP_METADATA } from './tokens';

@Injectable({
  providedIn: 'root',
})
export class ApplicationMetaDataService {
  constructor(
    @Inject(APP_METADATA)
    private applicationMetaData: ApplicationMetaData
  ) {}

  get data() {
    return this.applicationMetaData;
  }
}
