import { InjectionToken } from '@angular/core';
import { ApplicationErrorConfigs } from '../models/application-error-configs.model';
import { ApplicationMetaData } from '../models/application-meta-data.model';

export const BASE_URL = new InjectionToken<string>('BASE_URL');

export const LOG_LEVEL = new InjectionToken<number>('LOG_LEVEL');

export const APP_METADATA = new InjectionToken<ApplicationMetaData>(
  'APP_METADATA'
);

export const APP_ERROR_CONFIGS = new InjectionToken<ApplicationErrorConfigs>(
  'APP_ERROR_CONFIGS'
);
